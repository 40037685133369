import React from 'react'
import { makeStyles } from '@krowdy/kds-core/styles'
import clsx from 'clsx'
import { Typography } from '@krowdy/kds-core'

const videos = {
  candidates : 'https://s3.amazonaws.com/cdn.krowdy.com/media/videos/Video+ATS_v3+Candidatos.mp4',
  publication: 'https://s3.amazonaws.com/cdn.krowdy.com/media/videos/Video+ATS_v3+Publicaciones.mp4'
}

const Tutorial = ({
  videoType
}) => {
  const classes = useStyles()

  return (
    <div className={clsx(classes.marginTopBig, classes.largeMarginLeft)}>
      <video
        autoPlay
        className={clsx(classes.video, classes.marginTopMedium)}
        controls
        muted='muted'
        src={videoType === 'video-publication' ? videos.publication : videos.candidates} />

      <div className={clsx(classes.containerText, classes.secondary900, classes.marginTopLarge)}>
        <Typography
          align='center'
          className={classes.body1}
          variant='h3'> <strong> ¿Qué es Krowdy?</strong> </Typography>
        <Typography
          align='center'
          className={classes.body1}
          variant='h3'>Plataforma gratuita de reclutamiento digital</Typography>
        <ul className={classes.listText}>
          <li>
          Publica gratis en Laborum y en redes sociales
          </li>
          <li>
          Realiza múltiples evaluaciones a los candidatos
          </li>
          <li>
          Sigue todos tus procesos de manera digital y automática
          </li>
        </ul>

      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  body1: {
    fontSize  : 20,
    fontWeight: 'normal',
    lineHeight: '30px',
    textAlign : 'left'
  },
  containerText: {
    fontWeight: '500',
    maxWidth  : 620,
    textAlign : 'left'
  },

  largeMarginLeft: {
    marginLeft: theme.spacing(10)
  },
  listText: {
    fontSize  : 14,
    fontWeight: 'normal',
    lineHeight: '26px'
  },
  marginTopBig: {
    marginTop: theme.spacing(6.5)
  },
  marginTopLarge: {
    marginTop: theme.spacing(4.5)
  },
  secondary900: {
    color: '#00173D'
  },
  video: {
    maxWidth                    : 600,
    [theme.breakpoints.up(1460)]: {
      maxWidth: 760
    }
  }

}))

export default Tutorial
