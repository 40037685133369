import React from 'react'
import { makeStyles } from '@krowdy/kds-core/styles'

import Logo from './Logo'
// import {  Typography } from '@krowdy/kds-core'
import clsx from 'clsx'
import BannerAuth from './BannerAuth'
import Tutorial from './Tutorial'
// const illustration = 'https://s3.amazonaws.com/cdn.krowdy.com/media/images/MacbookProTilted.svg'

function BarbaAccount({ view, bannerType }) {
  const classes = useStyles()

  return (
    <div className={clsx(classes.containerRightSect, classes.backgroundImage)}>
      <div className={classes.container} >
        <div className={classes.containerForm}>
          <Logo />
          <div className={classes.ContainerView}>
            {view}
          </div>
        </div>
        {bannerType === 'image' ? <BannerAuth /> : <Tutorial videoType={bannerType} /> }
      </div>

    </div>
  )
}

const useStyles = makeStyles(theme => ({
  ContainerView: {
    alignItems: 'center',
    display   : 'flex',
    maxWidth  : '100%'
  },
  backgroundImage: {
    backgroundImage : 'url("https://s3.amazonaws.com/cdn.krowdy.com/media/images/background-barba.svg")',
    backgroundRepeat: 'no-repeat',
    backgroundSize  : 'cover'
  },
  body1: {
    fontSize  : 20,
    fontWeight: 'normal',
    lineHeight: '30px',
    textAlign : 'left'
  },
  container: {
    display       : 'flex',
    flexWrap      : 'wrap',
    justifyContent: 'center'
  },
  containerForm: {
    marginTop: theme.spacing(2.5)
  },
  containerRightSect: {
    flexDirection : 'row',
    height        : '100vh',
    justifyContent: 'space-between',
    overflow      : 'auto',
    position      : 'relative',
    width         : '100%'
  },
  containerText: {
    fontWeight : '500',
    maxWidth   : 620,
    paddingLeft: theme.spacing(5.5),
    textAlign  : 'left'
  },
  hexagono: {
    bottom  : 0,
    left    : 0,
    position: 'absolute',
    zIndex  : 0
  },

  illustration: {
    maxWidth                    : 800,
    [theme.breakpoints.up(1460)]: {
      maxWidth: 1000
    }
  },
  largeMarginLeft: {
    marginLeft: theme.spacing(2.5)
  },
  listText: {
    fontSize  : 14,
    fontWeight: 'normal',
    lineHeight: '26px'
  },
  marginTopLarge: {
    marginTop: theme.spacing(4.5)
  },
  marginTopMedium: {
    marginTop: theme.spacing(2.5)
  },
  root: {
    flexGrow: 1
  },
  secondary900: {
    color: '#00173D'
  },
  spaceBetween: {
    justifyContent: 'space-between',
    width         : '100%'
  },
  video: {
    maxWidth                    : 560,
    [theme.breakpoints.up(1460)]: {
      maxWidth: 700
    }
  }

}))

export default BarbaAccount
